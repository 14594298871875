<template>
  <div
    class="sbat-apparatus px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/SBATWeb-min.jpg"
          width="516"
          height="384"
          alt=""
          title="Simulated Bulk Autoignition Test Apparatus"
          class="img-responsive wp-image-12214"
        />
        <a
          class="heading-link"
          style="float:left;"
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2014-SMS-Friction-Machine-Standard-Features_C.pdf?alt=media&token=86302d91-31e3-43f6-9b0f-8c81468ba60f"
          target="_blank"
        >
          <div class="h-link">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book"
              class="svg-inline--fa fa-book fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
              ></path>
            </svg>
            <h2
              class="content-box-heading fusion-responsive-typography-calculated"
              style="display:inline; font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              SMS ABL Friction Machine Technical Specifications
            </h2>
          </div></a
        >
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <TitleComponent title="SMS SBAT Apparatus" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) produces a laboratory tool
            that can characterize a material’s bulk sensitivity to thermal
            energy for a wide variety of materials. The SMS Simulated Bulk
            Auto-Ignition Test (SBAT) Apparatus, originally developed at Alliant
            Techsystems Inc. (see Chemical Engineering Progress, September 1994,
            Vol 90 No 9, page 67), can be used to quickly and accurately
            determine the critical temperature of a given material as well as
            its bulk auto-ignition temperature. Its strength lies in its ability
            to rapidly and accurately obtain critical parameters to determine
            temperatures at which a material can be safely handled, processed,
            stored, or transported in bulk quantities.<br />
            Unplanned for incidents of runaway exothermic reactions can result
            in injury or fatality of personnel, large restoration costs, and
            larger future hidden costs. Knowledge of the thermal reactivity of
            energetic material can prevent an unplanned energetic event. Two
            properties that are critical to safely handling, processing,
            storing, and transporting energetic material are the bulk critical
            reactivity temperature and the bulk auto-ignition temperature.
          </p>
          <p>
            The critical reactivity temperature or critical temperature is the
            surrounding medium temperature at which the material could
            eventually reach ignition due to self-heating. The bulk
            auto-ignition temperature (bulk AIT) is the sample’s temperature at
            which the material will begin to give off significant amounts of
            energy in a short period of time and proceed to ignition. Knowledge
            of these two properties can assure safe operation, storage, or
            transportation by ensuring that when the ambient temperature is
            greater than the critical temperature, the material’s temperature is
            monitored to prevent against energetic decomposition at the bulk
            AIT.
          </p>
          <p>
            There are multiple methods to determine the critical temperature and
            the auto-ignition temperature including Differential Scanning
            Calorimetry (DSC), Simulated Bulk Auto-Ignition Test (SBAT), and
            Accelerating Rate Calorimetry (ARC). Important parameters to
            consider when choosing a particular method are the sample size,
            minimum detectible exothermic rate (or heating rate), and insulating
            quality. The sample size is important as the heat retained in the
            sample (particularly in a powdered form) can be greater with a
            greater quantity due in part to changes in the heat transfer rate
            from the sample to the surroundings. To better approximate the
            behavior of bulk material, testing of a larger sample size is best.
            Closely related to sample size dependencies is the minimum
            detectible rate and magnitude of temperature change between the
            heating element and the sample. Larger heating rates can mask the
            self-heating that frequently takes place in energetic materials.
            Also, the amount of thermal isolation between the sample and the
            environment affects the accuracy and sensitivity of the test. The
            differences in the three parameters (sample size, heat rate (or
            detectible heat rate), and level of thermal isolation) for DSC,
            SBAT, and ARC are given in the below table.
          </p>
          <p>
            <img
              class="alignleft size-full wp-image-12216"
              src="@/assets/TestEquipment/SBATARCDSCtable.gif"
              alt="SBATARCDSCtable"
              width="896"
              height="278"
            />
          </p>
          <p>
            Because of the level of thermal isolation, increased sample size,
            and confinement, the ARC test apparatus is widely used and regarded
            to give the most accurate results in determining the auto-ignition
            and critical reactivity temperatures, particularly for
            auto-catalytic materials. Additionally, unlike the DSC and SBAT, the
            ARC doesn’t merely increase the temperature at a prescribed rate but
            checks the temperature difference between the sample and heating
            element and attempts to match the temperature of the sample after a
            given time during an exothermal event. Such a ‘heat-wait-search’
            mode results in a very accurate estimate of how the material will
            behave in a bulk, well-insulated configuration. In comparison, due
            to the differences described above and in the table above, the
            experimental DSC auto-ignition temperature (AIT) can be elevated
            more than 50°C over the bulk AIT found using the ARC apparatus. The
            below plot shows the comparison between the auto-ignition
            temperature found with the DSC, SBAT and ARC for a range of
            different energetic materials. Note that the SBAT agrees with the
            ARC within 9 degrees Centigrade on average.
          </p>
          <p>
            <img
              class="alignright size-large wp-image-12217"
              src="@/assets/TestEquipment/ARCvsSBAT-1024x689.gif"
              alt="ARCvsSBAT"
              width="669"
              height="450"
            />
          </p>
          <p>
            Some of the disadvantages of the ARC include its high operating
            cost, long sampling time, and slow turnaround. The SBAT apparatus
            was developed to drastically reduce the sampling time and sample
            turnaround while still maintaining an accurate determination of bulk
            material behavior. The SBAT can test 5 samples simultaneously and
            the experimental AIT temperature found ~9°C above the AIT value of
            the ARC. In many cases, the SBAT is the ideal test apparatus to
            identify bulk critical reactivity and auto-ignition temperatures due
            to the number of ports, the rugged features, and the amount of
            insulation.
          </p>
          <p>
            The SBAT apparatus contains 6 ports or test chambers (including 1
            reference test chamber) arranged in a circular configuration in an
            aluminum block containing three 600W cartridge heaters. 3-5 grams of
            a sample can be loaded into each sample chamber, a thermocouple is
            then inserted into the sample, and the chamber is then closed with a
            vented cap. Depending on the test, the sample then undergoes heating
            at a rate of 0.2 °C/min or the sample can be tested isothermally at
            a given temperature.
          </p>
          <p>
            The ramped temperature test can yield the temperature at which
            exothermic or endothermic events occur under conditions of low heat
            loss from the sample. Isothermal test can be run to determine the
            critical temperature, or the temperature at which given an infinite
            storage time the sample will not react violently.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Simulated Bulk Auto-Ignition Temperature",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The SMS Simulated Bulk Auto-Ignition Temperature (SBAT) is designed to characterize a material's sensitivity to thermal energy. The SBAT provides an economic alternative to Accelerating Rate Calorimetry (ARC) with more accuracy than Differential Scanning Calorimetry (DSC). "
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.sbat-apparatus {
  .h-link:hover {
    color: #aa222c;
    .fa-book {
      color: #aa222c;
    }
  }
  .fa-book {
    color: black;
    width: 18px;
    display: inline;
  }
}
</style>
